// src/App.js
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Redirect from "./pages/Redirect"; // Assuming this is your custom component
import "bootstrap/dist/css/bootstrap.min.css";


function App() {
    return (
            <>
            <Router>
                <Routes>
                <Route path="/:slug" element={<Redirect />} />
                <Route path="/" element={<home />}        />      
                </Routes>
            </Router>
            <br/><br/><br/>
            </>
    );
}

export default App;
